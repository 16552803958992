export interface Project {
  title: string;
  description: string;
  link?: string; // assuming the link is optional
  tags: string[];
  images?: ProjectImage[];
}

export interface ProjectImage {
  url: string;
  alt: string;
}

const projects: Project[] = [
  {
    title: 'Art For Africa',
    link: 'http://ArtForAfrica.info',
    tags: ['xHTML', 'CSS', 'eCommerce', 'iCAL'],
    description:
      "A charitable digital platform born from a collaboration with artist Matt Wallace. Designed to honor Melanie Woodward's philanthropic endeavors.",
    images: [
      {
        url: '/assets/projects/art4africa.jpg',
        alt: 'Art for Africa main screen',
      },
    ],
  },
  {
    title: 'Canadian Pork Council (CPC/CCP)',
    link: 'http://www.cpc-ccp.com/',
    tags: ['CMS', 'PHP', 'MySql', 'JavaScript', 'CSS', 'xHTML', 'AJAX', 'iCAL'],
    description:
      'A custom content management system with news and events functionality, PDF-to-plaintext conversions, and phpBB integration. Features full French language support.',
  },
  {
    title: 'Canadian Hog Producers',
    tags: ['CMS', 'PHP', 'MySql', 'JavaScript', 'CSS', 'xHTML', 'AJAX', 'iCAL'],
    description:
      'A private portal offering up-to-date information, document management, calendar syncing, and bilingual (English and French) support.',
  },
  {
    title: 'Swinehealth.ca',
    link: 'http://www.swinehealth.ca/',
    tags: ['CMS', 'PHP', 'MySql', 'JavaScript', 'CSS', 'xHTML', 'AJAX'],
    description:
      'A network of seven interconnected sites, tailored for diverse audiences but sharing core functionalities. Full French language integration was a key feature.',
  },
  {
    title: 'Paradisal Imagery Inc.',
    link: 'http://www.paradisal.ca/',
    tags: ['PHP', 'MySql', 'JavaScript', 'eCommerce'],
    description:
      'A comprehensive site overhaul focused on enhanced user security, innovative features, and dynamic image watermarking.',
  },
  {
    title: 'TeleClass International',
    link: 'http://www.teleclassinternational.com/',
    tags: [
      'SEO',
      'XML',
      'PHP',
      'PERL',
      'MySql',
      'JavaScript',
      'Flash',
      'RSS',
      'eCommerce',
      'CRM',
      'AJAX',
    ],
    description:
      'Continuous enhancements and modular rewrites for a suite of teleconference platforms, each serving a unique audience.',
  },
  {
    title: 'Journal Anywhere',
    link: 'https://jrnl.com/',
    tags: ['PERL', 'MySql', 'Paypal IPN'],
    description:
      'A custom Instant Payment Solution for streamlining Paypal transactions, executed in collaboration with StellarWebSolutions.',
  },
  {
    title: 'Canada Events',
    link: 'http://www.canadaevents.ca/',
    tags: ['XML', 'ASP', 'PERL', 'MySql', 'JavaScript'],
    description:
      'Ongoing site maintenance with a focus on integrating dynamic XML feeds to showcase cinema listings.',
  },
  {
    title: 'Bangkok Restaurant',
    tags: ['MySql', 'PHP', 'JavaScript'],
    description:
      'A bespoke menu editor tool, designed to ingest and process data from various restaurant locations via CSV files.',
  },
  {
    title: 'DTC Forevermark',
    tags: ['PHP', 'MySql'],
    description:
      'A tailored solution to monitor and manage email bounces, ensuring efficient communication and user engagement.',
  },
  {
    title: 'SpyderCo',
    tags: ['PHP', 'YaBB'],
    description:
      'Customization of an open-source bulletin board system, adding multi-image posting capabilities and creating a premium feature set.',
  },
  {
    title: 'TellaFriend',
    tags: ['Perl', 'MySql', 'JavaScript'],
    description:
      'Transitioned a legacy email marketing software from a flat-file system to a more scalable MySql database structure.',
  },
  {
    title: 'Travel Masters',
    link: 'http://www.travelmasters.ca/',
    tags: ['PHP', 'XML', 'ASP.NET', 'SQL Server'],
    description:
      'A travel portal revamp with XML-integrated dynamic pages for hotels, cruises, and vacations. Internal tools were also crafted for agent access to new data feeds.',
  },
  {
    title: 'Sportlinker.com',
    link: 'http://www.sportlinker.com/',
    tags: ['Perl', 'MySql'],
    description:
      'An all-in-one sports management platform with team tracking and management capabilities. The site is fully maintained and expanded upon, with subdomains catering to specific niches.',
  },
  {
    title: 'Nortel Networks',
    tags: ['CRM', 'C++', 'Perl', 'XML', 'Oracle', 'JavaScript'],
    description:
      'Developed a suite of web applications to manage over 194k equipment units. Tools offered lifecycle tracking and timely upgrade notifications.',
  },
  {
    title: 'Collin Griffiths and Associates Ltd. (Grifftax)',
    tags: ['Visual C++', 'C++', 'HTML', 'JavaScript'],
    description:
      'Engineered a custom database solution for software user registration, developed eCommerce functionalities, and coded the core of a tax software that saw over 20k users in its first year.',
    images: [
      {
        url: '/assets/projects/grifftax1.jpg',
        alt: 'Description of project A image 2',
      },
      {
        url: '/assets/projects/grifftax2.jpg',
        alt: 'Description of project A image 2',
      },
      {
        url: '/assets/projects/grifftax3.jpg',
        alt: 'Description of project A image 2',
      },
    ],
  },
];

export default projects;
