import React from 'react';

import { StyledEngineProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import './font.css';
import Demo from './Demo';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Demo />
      </BrowserRouter>
    </StyledEngineProvider>
  </React.StrictMode>
);
