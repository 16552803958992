import React from 'react';

import { Box, Container, Divider } from '@mui/material';

import Footer from '../components/Footer';
import ProjectImageCard from '../components/ImageCard';
import PageLinkItem from '../components/PageLinkItem';
import PagesHeader from '../components/PagesHeader';
import Paragraph from '../components/Paragraph';
import SectionTitle from '../components/SectionTitle';
import TagList from '../components/TagList';
import projectsData, { Project } from '../data/Projects';

const projects: Project[] = projectsData;

const SolutionShowcase = () => {
  return (
    <Container>
      <PagesHeader />
      <Box>
        <SectionTitle
          first='SOLUTION'
          second='SHOWCASE'
          type='titles'
          gutterBottom
        />
        <Paragraph gutterBottom>
          Dive deep into our vast portfolio of solutions crafted with precision, dedication, and
          innovation. From startups to established enterprises, our team has successfully addressed
          unique challenges across various industries. With each solution, we&apos;ve not only
          achieved but exceeded our clients&apos; expectations. Discover how we turned ideas into
          reality, and how our solutions have driven growth and efficiency for our clients.
        </Paragraph>
        <Divider
          sx={{
            paddingTop: '20px',
          }}
        />
        {projects.map((project, index) => (
          <Box
            key={index}
            marginBottom={4}
          >
            <Box
              display='flex'
              alignItems='center'
              sx={{
                paddingBottom: '10px',
              }}
            >
              <SectionTitle
                first={project.title}
                second=''
                type='menu'
                variant='body1'
                padding='0'
              />
              <Box>
                {project.link && (
                  <PageLinkItem
                    third={project.link}
                    url={project.link}
                    type='pageLink'
                  />
                )}
              </Box>
            </Box>
            <TagList tags={project.tags} />
            <Paragraph>{project.description}</Paragraph>
            {project.images && (
              <ProjectImageCard
                images={project.images}
                title={project.title}
              />
            )}
            <Divider
              sx={{
                paddingTop: '20px',
              }}
            />
          </Box>
        ))}
      </Box>

      <Footer />
    </Container>
  );
};

export default SolutionShowcase;
