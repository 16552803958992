import React from 'react';

import { ListItemButton, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';

import SectionTitle from './SectionTitle';

interface SelectedListButtonProps {
  label1: string;
  label2: string;
  path: string; // assuming the To type you mentioned is string. Replace with appropriate type if not.
  index: number;
  icon: React.ElementType; // assuming it's a React component. Adjust as needed.
  isSelected: boolean;
  onClick: (path: string, index: number) => void;
  onPress: (path: string, index: number) => (event: React.KeyboardEvent) => void;
}

export default function SelectedListButton({
  label1,
  label2,
  path,
  icon: IconComponent,
  index,
  isSelected,
  onClick,
  onPress,
}: SelectedListButtonProps) {
  return (
    <ListItemButton
      component={Link}
      to={path}
      autoFocus={true}
      selected={isSelected}
      onClick={() => onClick(path, index)}
      onKeyDown={(event) => onPress?.(path, index)?.(event)}
      sx={{
        padding: '20px',
        '&.Mui-selected': {
          backgroundColor: '#00e1fc',
          color: 'white',
          '& .blue, & .grey': {
            color: 'white',
            fontWeight: '700',
          },
        },
        '&.Mui-focusVisible': {
          backgroundColor: '#009bfb',
          color: 'white',
          '& .blue, & .grey': {
            color: 'white',
          },
        },
        ':hover': {
          backgroundColor: '#e7e2e2',
          color: 'black',
          cursor: 'pointer',
          '& .blue, & .grey': {
            color: 'black',
          },
        },
      }}
    >
      <ListItemIcon>
        <IconComponent
          sx={{
            ':hover': {
              color: 'black',
            },
          }}
        />
      </ListItemIcon>
      <SectionTitle
        first={label1}
        second={label2}
        type='menu'
        variant='subtitle1'
        padding='0'
      />
    </ListItemButton>
  );
}
