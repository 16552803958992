import React from 'react';

import Helmet from 'react-helmet';

function SEO() {
  return (
    <Helmet>
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link
        rel='manifest'
        href='/site.webmanifest'
      />
      <link
        rel='mask-icon'
        href='/safari-pinned-tab.svg'
        color='#5bbad5'
      />
      <meta
        name='apple-mobile-web-app-title'
        content='Scaled Tech Consulting'
      />
      <meta
        name='application-name'
        content='Scaled Tech Consulting'
      />
      <meta
        name='msapplication-TileColor'
        content='#da532c'
      />
      <meta
        name='msapplication-TileImage'
        content='/mstile-144x144.png'
      />
      <meta
        name='theme-color'
        content='#ffffff'
      />
      {/* <!-- Standard Favicon --> */}
      <link
        rel='icon'
        type='image/x-icon'
        href='/favicon.ico'
      />
      {/* <!-- For iPhone 4 Retina display: --> */}
      <link
        rel='apple-touch-icon-precomposed'
        sizes='114x114'
        href='/apple-touch-icon-114x114-precomposed.png'
      />
      {/* <!-- For iPad: --> */}
      <link
        rel='apple-touch-icon-precomposed'
        sizes='72x72'
        href='/apple-touch-icon-72x72-precomposed.png'
      />
      {/* <!-- For iPhone: --> */}
      <link
        rel='apple-touch-icon-precomposed'
        href='/apple-touch-icon-57x57-precomposed.png'
      />
    </Helmet>
  );
}

export default SEO;
