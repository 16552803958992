import React from 'react';

import { ListItem, ListItemButton } from '@mui/material';
import { ListItemProps } from '@mui/material/ListItem';
import { Link } from 'react-router-dom';

import SectionTitle from './SectionTitle';

type TypeValue = 'titles' | 'subtitles' | 'menu' | 'pageLink';
type VariantValue = 'subtitle1' | 'h6' | 'body1';

interface PageLinkItemProps extends ListItemProps {
  first?: string;
  second?: string;
  third?: string;
  type?: TypeValue;
  variant?: VariantValue;
  url?: string;
}

const PageLinkItem: React.FC<PageLinkItemProps> = ({
  first,
  second,
  third,
  type = 'menu',
  variant,
  url,
  ...props
}) => {
  return (
    <ListItem
      disablePadding
      {...props}
    >
      <ListItemButton
        component={url ? Link : 'div'}
        to={url}
      >
        <SectionTitle
          first={first}
          second={second}
          third={third}
          type={type}
          variant={variant}
          padding='0'
        />
      </ListItemButton>
    </ListItem>
  );
};

export default PageLinkItem;
