import React, { useEffect, useState } from 'react';

import { Grid, Typography, List, ListItem, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';

import PageLinkItem from './PageLinkItem';
import SectionTitle from './SectionTitle';
import routes from '../data/Navigation';

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const cachedYear = localStorage.getItem('copyrightYear');
    if (cachedYear) {
      setCurrentYear(Number(cachedYear));
    } else {
      localStorage.setItem('copyrightYear', currentYear.toString());
    }
  }, []);

  return (
    <Grid
      container
      spacing={4}
      sx={{ padding: '20px', marginTop: '50px' }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
      >
        <SectionTitle
          first='Contact'
          second='Info'
        />
        <Typography>Coral Springs, FL</Typography>
        <Typography></Typography>
        <Typography>info@scaledtech.consulting</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={3}
      >
        <SectionTitle
          first='Navigation'
          second=''
        />
        <List>
          {routes
            .filter((route) => route.sidebar)
            .map((route) => (
              <ListItem
                key={route.label1}
                disablePadding
              >
                <ListItemButton
                  component={Link}
                  to={route.path}
                >
                  <SectionTitle
                    first={route.label1}
                    second={route.label2}
                    type='menu'
                    variant='body1'
                    padding='0'
                  />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        md={3}
      >
        <SectionTitle
          first='Social'
          second='Media'
        />
        <List>
          <PageLinkItem
            first='Facebook'
            second=''
            url='https://facebook.com'
          />
          <PageLinkItem
            first='Twitter'
            second=''
            url='https://twitter.com'
          />
          <PageLinkItem
            first='LinkedIn'
            second=''
            url='https://linkedIn.com'
          />
        </List>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        md={3}
      >
        <SectionTitle
          first='Legal'
          second=''
        />
        <List>
          <ListItem
            key='toc'
            disablePadding
          >
            <ListItemButton
              component={Link}
              to='/terms-and-conditions'
            >
              <SectionTitle
                first='Terms'
                second='/Conditions'
                type='menu'
                variant='body1'
                padding='0'
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key='privacy'
            disablePadding
          >
            <ListItemButton
              component={Link}
              to='/privacy'
            >
              <SectionTitle
                first='Privacy'
                second='Policy'
                type='menu'
                variant='body1'
                padding='0'
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography
          variant='body2'
          align='center'
        >
          © {currentYear} Scaled Tech Consulting. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
