import React from 'react';

import { Box, Chip, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box';

type TagsType = string[] | { [group: string]: string[] };
interface TagListProps extends BoxProps {
  tags: TagsType;
}

const TagList: React.FC<TagListProps> = ({ tags, ...props }) => {
  // Function to render a list of tags
  const renderTags = (tagArray: string[]) => (
    <Box
      display='flex'
      flexWrap='wrap'
      gap={1}
      {...props}
    >
      {tagArray.map((tag) => (
        <Chip
          key={tag}
          label={tag}
          variant='outlined'
          size='small'
        />
      ))}
    </Box>
  );

  // Determine if tags are grouped or not
  if (Array.isArray(tags)) {
    return renderTags(tags);
  } else {
    return (
      <Box
        display='flex'
        flexWrap='wrap'
        gap={2}
        {...props}
      >
        {Object.entries(tags).map(([group, tagArray]) => (
          <Box
            key={group}
            border={1}
            borderColor='grey.300'
            borderRadius='borderRadius'
            position='relative'
            pl={2}
            pr={2}
            pt={1}
            pb={2}
          >
            <Typography
              variant='caption'
              component='span'
              sx={{
                position: 'absolute',
                top: -12,
                left: 10,
                backgroundColor: 'white',
                paddingLeft: 1,
                paddingRight: 1,
                color: 'grey.600',
                fontSize: '0.8rem',
              }}
            >
              {group}
            </Typography>
            {renderTags(tagArray)}
          </Box>
        ))}
      </Box>
    );
  }
};

export default TagList;
