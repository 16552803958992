import React from 'react';

import { Box, Container, Grid } from '@mui/material';

import graphicImage3 from '../assets/images/graphic3.webp';
import graphicImage4 from '../assets/images/graphic4.webp';
import Footer from '../components/Footer';
import PagesHeader from '../components/PagesHeader';
import Paragraph from '../components/Paragraph';
import SectionTitle from '../components/SectionTitle';
import TagList from '../components/TagList';

function Services() {
  return (
    <Container>
      <PagesHeader />
      <SectionTitle
        first='COMPANY'
        second='SERVICES'
        type='titles'
      />

      <Paragraph>
        At Scaled Tech Consulting, we acknowledge that contemporary digital solutions are
        multifaceted. Each unique challenge demands a blend of services, tailored to ensure success.
        Drawing from a pool of seasoned developers with diverse expertise, we assure a holistic
        approach to problem-solving. Let us navigate the technological maze and sculpt solutions
        fitting your distinct needs.
      </Paragraph>
      <Grid
        container
        spacing={3}
        sx={{ paddingTop: '40px' }}
      >
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
        >
          <SectionTitle
            first='WEB'
            second='APPLICATIONS'
            gutterBottom
          />
          <TagList
            tags={['React', 'Vue.js', 'Angular', 'Node.js', 'Java', 'TypeScript', 'GraphQL']}
          />
          <Paragraph>
            Web applications empower businesses, transforming how they interact with users and edge
            past competitors. Whether you&apos;re aiming to offer in-depth insights into your
            products, simplify online transactions, or offer efficient customer support, our team
            crafts bespoke solutions. We&apos;ve architected everything from real-time travel
            updates for tourism platforms, to comprehensive e-commerce integrations, to global
            communication systems. Your vision, our expertise.
          </Paragraph>
          <SectionTitle
            first='WEB'
            second='DESIGN'
            gutterBottom
          />
          <TagList
            tags={['HTML5', 'CSS3', 'JavaScript', 'TypeScript', 'React', 'Vue.js', 'Angular']}
          />
          <Paragraph>
            While static web pages have their role, integrating them with dynamic applications
            brings life to digital platforms. These seemingly static pages, enriched with
            interactivity, enhance user engagement. Besides offering captivating aesthetics, we
            ensure that search engines favor you, integrating the best SEO practices.
          </Paragraph>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={6}
        >
          <Box
            component='img'
            src={graphicImage3}
            alt='Abstract futuristic image, looks like fiber optic cables'
            sx={{
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Grid>
      </Grid>
      <SectionTitle
        first='DATABASE'
        second='INTEGRATION'
        gutterBottom
      />
      <TagList tags={['PostgreSQL', 'MongoDB', 'MySQL', 'Redis', 'NoSQL']} />
      <Paragraph>
        Efficient data storage and retrieval form the backbone of most digital solutions. From
        complex relational databases to nimble NoSQL stores, our team ensures data integrity and
        performance. Whether it&apos;s about managing intricate data structures or crafting
        optimized queries, trust us to manage your data landscape.
      </Paragraph>
      <Grid
        container
        spacing={3}
        sx={{ paddingTop: '40px' }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          md={6}
        >
          <Box
            component='img'
            src={graphicImage4}
            alt='Abstract futuristic image, looks like fiber optic cables'
            sx={{
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={6}
        >
          <SectionTitle
            first='CLOUD-INFRASTRUCTURE'
            second='/DEPLOYMENT'
            gutterBottom
          />
          <TagList tags={['Nginx', 'Apache', 'AWS', 'Azure', 'GCP']} />
          <Paragraph>
            In today&apos;s digital era, the foundation of robust digital solutions lies in the
            cloud. Our expertise is not just limited to traditional server setups, but extends to
            designing, implementing, and managing sophisticated cloud infrastructures.
          </Paragraph>
          <Paragraph>
            <strong>Public Cloud</strong>: Leverage the vast resources provided by major cloud
            providers like AWS, Azure, or GCP. Ideal for businesses seeking cost-effective solutions
            without the hassle of infrastructure maintenance.
          </Paragraph>
          <Paragraph>
            <strong>Private Cloud</strong>: For businesses with stringent data security requirements
            or unique infrastructure needs, we set up dedicated cloud environments. This ensures
            optimal performance and heightened security.
          </Paragraph>
          <Paragraph>
            <strong>Hybrid Cloud</strong>: Get the best of both worlds. We can architect solutions
            that combine on-premises, private cloud, and third-party public cloud services. This
            results in greater flexibility, optimized infrastructure costs, and better security
            configurations.
          </Paragraph>
          <Paragraph>
            By understanding your specific business needs, we choose the right cloud architecture,
            ensuring scalability, security, and operational efficiency.
          </Paragraph>
        </Grid>
      </Grid>
      <SectionTitle
        first='CROSS-PLATFORM'
        second='INTEGRATIONS'
        gutterBottom
      />
      <TagList
        tags={{
          Desktop: ['Linux', 'Windows', 'macOS'],
          Web: ['Chrome', 'Firefox', 'Edge'],
          Mobile: ['Android', 'iOS'],
        }}
        sx={{ paddingTop: '5px' }}
      />
      <Paragraph>
        While web applications dominate the landscape, enduring desktop applications often seek
        cloud integrations. From software updates, user authentication, to data syncs, we ensure
        your applications are cloud-ready, providing a cohesive experience across platforms.
      </Paragraph>
      <SectionTitle
        first='CONTAINERIZATION'
        second='/ORCHESTRATION'
        gutterBottom
      />
      <TagList tags={['Docker', 'Kubernetes']} />
      <Paragraph>
        In the modern development landscape, ensuring consistent behavior across various
        environments is crucial. This is where containerization comes into play:
      </Paragraph>
      <Paragraph>
        <strong>Docker</strong>: Docker allows us to create, deploy, and run applications by using
        containers. By containerizing the application platform and its dependencies, discrepancies
        in terms of &ldquo;It works on my machine&rdquo; become a thing of the past.
      </Paragraph>
      <Paragraph>
        <strong>Kubernetes</strong>: As applications scale, managing and automating various Docker
        containers becomes a challenge. Kubernetes serves as an orchestration platform, ensuring
        that your containerized applications run seamlessly, scale efficiently, and remain resilient
        against failures.
      </Paragraph>
      <Paragraph>
        Our team is equipped with the expertise to implement these technologies, ensuring your
        applications are both scalable and reliable, no matter the scale or complexity.
      </Paragraph>
      <Footer />
    </Container>
  );
}

export default Services;
