import React from 'react';

import BuildIcon from '@mui/icons-material/Build';
import PuzzleIcon from '@mui/icons-material/Extension';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom';

import AboutUs from '../pages/AboutUs';
import ContactUs from '../pages/ContactUs';
import Home from '../pages/Home';
import Services from '../pages/Services';
import Solutions from '../pages/Solutions';

const routes = [
  {
    label1: 'Home',
    label2: 'Page',
    path: '/',
    component: Home,
    sidebar: true,
    icon: HomeIcon,
    index: 1,
  },
  {
    label1: 'About',
    label2: 'Us',
    path: '/about-us',
    component: AboutUs,
    sidebar: true,
    icon: PeopleIcon,
    index: 2,
  },
  {
    label1: 'Our',
    label2: 'Solutions',
    path: '/solutions',
    component: Solutions,
    sidebar: true,
    icon: PuzzleIcon,
    index: 3,
  },
  {
    label1: 'Our',
    label2: 'Services',
    path: '/services',
    component: Services,
    sidebar: true,
    icon: BuildIcon,
    index: 4,
  },
  {
    label1: 'Contact',
    label2: 'Us',
    path: '/contact-us',
    component: ContactUs,
    sidebar: true,
    icon: MailIcon,
    index: 5,
  },
  {
    label1: '',
    label2: '',
    path: '*',
    component: NoMatch,
    sidebar: false,
    icon: HomeIcon,
    index: 0,
  },
];

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to='/'>Go to the home page</Link>
      </p>
    </div>
  );
}

export default routes;
