import { Box, Container, Grid, Typography } from '@mui/material';

import graphicImage1 from '../assets/images/graphic1.webp';
import Footer from '../components/Footer';
import PagesHeader from '../components/PagesHeader';
import Paragraph from '../components/Paragraph';
import SectionTitle from '../components/SectionTitle';

const Home: React.FC = () => {
  return (
    <Container
      sx={{
        paddingTop: '40px',
        paddingBottom: '140px',
      }}
    >
      <Container
        sx={{
          paddingTop: '10px',
        }}
      >
        <PagesHeader />
        <SectionTitle
          first='WELCOME'
          second='MESSAGE'
          type='titles'
          variant='h6'
        />
        <Paragraph>
          Welcome to Scaled Tech Consulting! Our digital presence is a reflection of our commitment
          to harnessing the very best of today&apos;s technologies. Dive deep into our website, and
          discover how we can transform your ideas into reality. And if you ever find yourself with
          unanswered questions, our lines of communication are always open.
        </Paragraph>
      </Container>
      <Container
        sx={{
          paddingTop: '20px',
        }}
      >
        <SectionTitle
          first='COMPANY'
          second='SERVICES'
          type='titles'
        />
      </Container>
      <Container
        sx={{
          paddingTop: '20px',
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={3}
            md={6}
          >
            <Box
              component='img'
              src={graphicImage1}
              alt='Abstract futuristic image, looks like fiber optic cables'
              sx={{
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
          >
            <Typography
              className='neuropol uppercase titles'
              variant='h6'
            >
              <span>Our Cutting-Edge Expertise</span>
            </Typography>
            <SectionTitle
              first='Web'
              second='Applications'
            />
            <Paragraph>
              Dive into interactive, responsive web applications, harnessing the power of React,
              Vue.js, and Angular.
            </Paragraph>
            <SectionTitle
              first='Mobile'
              second='Apps'
            />
            <Paragraph>
              Experience seamless cross-platform mobile applications, crafted with finesse in React
              Native and Flutter.
            </Paragraph>
            <SectionTitle
              first='Web'
              second='Design'
            />
            <Paragraph>
              Visually captivating and functionally exceptional designs, leveraging HTML5, CSS3, and
              cutting-edge JavaScript dynamics.
            </Paragraph>
            <SectionTitle
              first='Database'
              second='Integration'
            />
            <Paragraph>
              Fortify your operations with reliable, performant databases, from MongoDB and
              PostgreSQL to MySQL
            </Paragraph>
            <SectionTitle
              first='SERVER'
              second='OPTIMIZATION'
            />
            <Paragraph>
              Guarantee seamless, high-speed user interactions powered by industry standards like
              Nginx and Apache.
            </Paragraph>
            <SectionTitle
              first='Backend'
              second='Development'
            />
            <Paragraph>
              Engineer scalable, efficient backend architectures with the robustness of Node.js and
              Express.js.
            </Paragraph>
            <SectionTitle
              first='CLOUD'
              second='ENGINEERING'
            />
            <Paragraph>
              Elevate to the cloud, harnessing scalable, resilient solutions on platforms like AWS,
              Google Cloud, and Azure.
            </Paragraph>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          paddingTop: '40px',
        }}
      >
        <Typography
          className='neuropol uppercase titles'
          variant='h6'
        >
          <span>WHY CHOOSE SCALED TECH CONSULTING?</span>
        </Typography>
        <SectionTitle
          first='VAST'
          second='Experience'
        />
        <Paragraph>
          Decades navigating tech terrains enable us to tackle even the most formidable challenges
          with grace and proficiency.
        </Paragraph>
        <SectionTitle
          first='TRENDSETTER'
          second='INNOVATION'
        />
        <Paragraph>
          Beyond mere trend-following, we&apos;re the pioneers shaping the future. Expect
          cutting&mdash;edge solutions that set industry standards.
        </Paragraph>
        <SectionTitle
          first='CLIENT-FOCUSED'
          second='PARTNERSHIP'
        />
        <Paragraph>
          Driven by your vision, our collaboration ensures every detail aligns perfectly with your
          objectives and aspirations.
        </Paragraph>
        <SectionTitle
          first='DYNAMIC'
          second='SCALABILITY'
        />
        <Paragraph>
          Tailored solutions that expand effortlessly. We provide adaptive frameworks suitable for
          sprouting startups to global enterprises.
        </Paragraph>
      </Container>
      <Container
        sx={{
          paddingTop: '40px',
        }}
      >
        <SectionTitle
          first='Start'
          second='Today'
        />
        <Paragraph>
          Dreaming of a digital transformation? Let&apos;s make it happen together. Contact us today
          and embark on a tech journey tailored just for you.
        </Paragraph>
      </Container>
      <Footer />
    </Container>
  );
};

export default Home;
