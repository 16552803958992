import React, { useState } from 'react';

import { Box, Card, CardActionArea, CardMedia } from '@mui/material';

interface ProjectImage {
  url: string;
  alt: string;
}

interface ProjectImageCardProps {
  images: ProjectImage[];
  title: string;
}

const ProjectImageCard: React.FC<ProjectImageCardProps> = ({ images }) => {
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<ProjectImage | null>(null);
  const [hoveredImage, setHoveredImage] = useState<string | null>(null); // to manage hover state for overlay

  return (
    <Box>
      {isLightboxOpen && currentImage && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={() => setLightboxOpen(false)}
        >
          <img
            src={currentImage.url}
            alt={currentImage.alt}
            style={{ maxWidth: '80%', maxHeight: '80%' }}
          />
        </Box>
      )}

      <Box
        display='flex'
        flexWrap='wrap'
        gap={2}
      >
        {images.map((image) => (
          <Card
            key={image.url}
            sx={{ maxWidth: 345, marginBottom: 2, position: 'relative' }}
            onMouseEnter={() => setHoveredImage(image.url)}
            onMouseLeave={() => setHoveredImage(null)}
            onClick={() => {
              setCurrentImage(image);
              setLightboxOpen(true);
            }}
          >
            <CardActionArea>
              <CardMedia
                component='img'
                alt={image.alt}
                height='140'
                image={image.url}
                title='Project Image'
              />
              {hoveredImage === image.url && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'opacity 0.3s',
                  }}
                ></Box>
              )}
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default ProjectImageCard;
