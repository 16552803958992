import React from 'react';

import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

interface SectionTitleProps extends TypographyProps {
  first?: string;
  second?: string;
  third?: string;
  type?: TypeValue;
  variant?: VariantValue;
  padding?: string;
}

type TypeValue = 'titles' | 'subtitles' | 'menu' | 'pageLink';
type VariantValue = 'subtitle1' | 'h6' | 'body1';

const SectionTitle: React.FC<SectionTitleProps> = ({
  first,
  second,
  third,
  type = 'subtitles',
  variant = 'subtitle1',
  padding = '20',
  ...props
}) => {
  return (
    <Typography
      className={`neuropol uppercase ${type}`}
      variant={`${variant}`}
      sx={{
        paddingTop: `${padding}px`,
      }}
      {...props}
    >
      {first && <span className='blue'>{first}</span>}
      {second && <span className='grey'>{second}</span>}
      {third && <span className='black'>{third}</span>}
    </Typography>
  );
};

export default SectionTitle;
