import * as React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Routes, Route, useNavigate } from 'react-router-dom';

import ItemButton from './components/ItemButton';
import routes from './data/Navigation';
import SEO from './helpers/SEO';
import theme from './theme';
import './App.css';

export default function Demo() {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [drawer, setDrawer] = React.useState(false);

  const demoTheme = useTheme();
  const isSmallScreen = useMediaQuery(demoTheme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const menuClick = (path: string, index: number) => {
    setDrawer(false);
    setSelectedIndex(index);
    navigate(path);
  };

  const handleKeyPress = (path: string, index: number) => (event: React.KeyboardEvent) => {
    if (event && event.type === 'keydown' && (event.key === 'Enter' || event.key === 'Space')) {
      event.preventDefault();
      setDrawer(false);
      setSelectedIndex(index);
      navigate(path);
    }
    event.stopPropagation();
  };

  const list = (anchor: string) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {routes
          .filter((route) => route.sidebar)
          .map((route) => (
            <ListItem
              key={route.label1}
              disablePadding
            >
              <ItemButton
                label1={route.label1}
                label2={route.label2}
                path={route.path}
                icon={route.icon}
                index={route.index}
                isSelected={selectedIndex === route.index}
                onClick={menuClick}
                onPress={handleKeyPress}
              />
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            onClick={toggleDrawer(true)}
            edge='start'
            color='inherit'
            aria-label='menu'
          >
            <MenuIcon />
          </IconButton>
          <Box
            component='span'
            mr={2}
            sx={{ paddingLeft: { xs: '10px', md: '40px' } }}
          >
            <img
              src='./assets/images/ScaledTechLogoSmall.png'
              alt='Scaled Tech Consulting Company Logo'
              style={{ height: '40px', width: '41px' }}
            />
          </Box>
          <Typography
            className='neuropol uppercase appBarTitle'
            variant={isSmallScreen ? 'h6' : 'h5'}
          >
            <span className='ltblue'>Scaled</span>
            <span className='blue'>Tech</span>
            <span className='grey'>Consulting</span>
          </Typography>
        </Toolbar>
      </AppBar>
      <nav>
        <SwipeableDrawer
          anchor={'left'}
          open={drawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list('left')}
        </SwipeableDrawer>
      </nav>
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </ThemeProvider>
  );
}
