import React from 'react';

import { Container, Typography, TextField, Button } from '@mui/material';

import Footer from '../components/Footer';
import PagesHeader from '../components/PagesHeader';
import SectionTitle from '../components/SectionTitle';

function ContactUs() {
  return (
    <Container>
      <PagesHeader />
      <SectionTitle
        first='Contact'
        second='Us'
        type='titles'
      />

      <Typography variant='body1'>Feel free to reach out to us.</Typography>

      <form>
        <TextField
          label='Name'
          fullWidth
          margin='normal'
        />
        <TextField
          label='Email'
          fullWidth
          margin='normal'
        />
        <TextField
          label='Message'
          multiline
          rows={4}
          fullWidth
          margin='normal'
        />

        <Button
          variant='contained'
          color='secondary'
          type='submit'
          aria-label='Send Message'
        >
          Submit
        </Button>
      </form>
      <Footer />
    </Container>
  );
}

export default ContactUs;
