import React from 'react';

import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

// Extending TypographyProps allows you to use all the properties of the Typography component.
interface ParagraphProps extends TypographyProps {
  padding?: string;
}

const Paragraph: React.FC<ParagraphProps> = ({ padding = '10', ...props }) => {
  return (
    <Typography
      // Inline styles for custom padding
      sx={{
        paddingTop: `${padding}px`,
      }}
      // Spread all other props from TypographyProps, e.g., variant, color, etc.
      {...props}
      // Always have a bottom gutter
      gutterBottom
    />
  );
};

export default Paragraph;
