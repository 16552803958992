import React from 'react';

import { Box, Container, Grid } from '@mui/material';

import graphicImage1 from '../assets/images/OurTeams.webp';
import Footer from '../components/Footer';
import PagesHeader from '../components/PagesHeader';
import Paragraph from '../components/Paragraph';
import SectionTitle from '../components/SectionTitle';

function AboutUs() {
  return (
    <Container
      sx={{
        paddingTop: '40px',
        paddingBottom: '140px',
      }}
    >
      <Container
        sx={{
          paddingTop: '10px',
        }}
      >
        <PagesHeader />
        <SectionTitle
          first='ABOUT'
          second='US'
          type='titles'
          variant='h6'
        />
        <Paragraph>Welcome to Scaled Tech Consulting.</Paragraph>
        <Paragraph>
          Born from a vision two decades ago, Scaled Tech Consulting has matured into a leading name
          in the tech industry. Despite our years, our spirit remains untamed, as vibrant and
          fervent as a start-up, yet with the experience only years in the business can bring.
        </Paragraph>
        <Paragraph>
          Our journey began 22 years ago, rooted in the commitment to deliver unparalleled
          technological solutions. Today, our foundation stands strong, fortified by a reservoir of
          knowledge drawn from developers with an average of 20 years of industry experience. Such
          expertise isn&apos;t just confined within the walls of our headquarters. While our primary
          hubs are nestled in the heart of the US and Canada, our influence is truly global. Virtual
          teams collaborate on projects that span continents, breaking boundaries, and setting new
          standards.
        </Paragraph>
      </Container>

      <Container
        sx={{
          paddingTop: '20px',
        }}
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={9}
            md={6}
          >
            <SectionTitle
              first='OUR'
              second='TEAMS'
              type='titles'
            />
            <Paragraph>
              At Scaled Tech Consulting, we understand the ebb and flow of the tech tide.
              Technologies evolve, projects shift in scope, but our commitment to excellence remains
              constant. While we boast a core team of highly skilled developers, we recognize the
              dynamism required in this ever-changing landscape. This is why we&apos;ve cultivated
              relationships with a vast pool of seasoned professionals, ensuring that we can
              dynamically scale, adapting to the demands of any project, regardless of its size or
              complexity.
            </Paragraph>
            <Paragraph>
              By harnessing the collective intelligence of our global community of developers, we
              ensure that our clients receive the best of both worlds: the dedication and continuity
              of our core team combined with the specialized skills of our extended family of
              professionals.
            </Paragraph>
            <Paragraph>
              Join us on this exciting journey as we continue to reshape the tech landscape, one
              project at a time.
            </Paragraph>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={6}
          >
            <Box
              component='img'
              src={graphicImage1}
              alt='Description of Image'
              sx={{
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Container>
  );
}

export default AboutUs;
